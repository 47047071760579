<template>
  <div class="takeClothesIndexView content-index">
    <div class="topView">
      <div class="tView">
        <el-page-header @back="$router.go(-1)" content="已上挂列表"></el-page-header>
      </div>
      <div class="searchView">
        <div class="dateView">
          <el-date-picker
              v-model="dateRange"
              type="daterange"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions">
          </el-date-picker>
        </div>
        <div class="keywordView">
          <el-button type="success" @click="handleQuery">确认</el-button>
        </div>
      </div>
      <div style="padding-top: 10px;font-size: 14px;font-weight: 600">
        上挂件数: {{ total }}件
      </div>
    </div>
    
    <div class="contentView">
      <div class="tableTitle">
        <el-table
            :header-cell-style="{ color:'#363636',fontWeight:'500'}"
            v-loading="showQueryLoading"
            :data="dataList"
            style="width: 100%">
          <el-table-column
              label="序号"
              width="70px"
              align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
              prop="outClothesNumber"
              label="衣物码"
              align="center">
          </el-table-column>
          <el-table-column
              prop="userName"
              label="姓名"
              align="center">
          </el-table-column>
          <el-table-column
              prop="userPhone"
              label="电话"
              align="center">
              <template slot-scope="scope">
              <span v-hidephone>{{ scope.row.userPhone}}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="clothesName"
              label="衣物名称"
              align="center">
          </el-table-column>
          <el-table-column
              prop="colorName"
              label="颜色"
              align="center">
          </el-table-column>
          <el-table-column
              prop="currentPrice"
              label="实收价格"
              align="center">
          </el-table-column>
          <el-table-column
              prop="clothesStatusStr"
              label="状态"
              align="center">
          </el-table-column>
          <el-table-column
              prop="operateTime"
              label="上挂时间"
              width="180"
              align="center">
          </el-table-column>
          <el-table-column
              prop="remark"
              label="店长备注"
              align="center">
          </el-table-column>
          <el-table-column label="订单详情" align="center">
            <template slot-scope="scope">
              <el-button size="small" type="primary" @click="getClothesOrderDetails(scope.row.orderNumber)">关联订单
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
            background
            layout="total,prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>

    <!-- 订单详情 -->
    <transition name="userDetails">
      <order-detail v-if="dialogTableVisible" :orderDetailOption="orderDetailOption"
                    @diaLogIsShow="dialogClose"></order-detail>
    </transition>


  </div>

</template>

<script>
import {hangClothesPage} from '@/api/clothes/clothes'
import {getClothesOrderDetails} from '@/api/clothes/order'
import OrderDetail from '@/components/OrderDetails'

export default {
  name: "alreadyShelves",
  data() {
    return {
      dateRange: [this.$moment().format("YYYY-MM-DD"), this.$moment().format("YYYY-MM-DD")],
      pickerOptions: {
        shortcuts: [
          {
            text: '昨天',
            onClick(picker) {
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, start]);
            }
          },
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }],
        onPick: ({maxDate, minDate}) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 30 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        }
      },
      showQueryLoading: false,
      dataList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0, // 总数量
      selectDate: '',
      dialogTableVisible: false,
      orderDetailOption: {
        clothesList: []
      },
    }
  },
  components: {
    OrderDetail
  },
  created() {
    this.handleQuery()
  },
  methods: {
    // 处理查询
    handleQuery() {
      let _this = this;
      const params = new URLSearchParams()
      params.append('current', this.currentPage)
      params.append('size', this.pageSize)
      params.append('beginTime', this.dateRange ? this.dateRange[0] : '');
      params.append('endTime', this.dateRange ? this.dateRange[1] : '');
      _this.showQueryLoading = true
      hangClothesPage(params).then(response => {
        this.showQueryLoading = false
        console.log(response);
        _this.dataList = response.data.data.records;
        _this.total = response.data.data.total
      })
    },
    handleCurrentChange: function (val) {
      this.currentPage = val
      this.handleQuery()
    },
    //获取订单详情
    getClothesOrderDetails(orderNumber) {
      this.dialogTableVisible = true;
      this.orderInfoLoading = true;
      this.orderNumber = orderNumber;
      getClothesOrderDetails(orderNumber).then(response => {
        this.orderInfoLoading = false;
        this.orderDetailOption = response.data.data;
      })
    },
    dialogClose(isShow) {
      this.dialogTableVisible = isShow
    },
  }
}
</script>

<style scoped lang="scss">

.takeClothesIndexView {
  position: relative;
  font-family: PingFangSC-Regular;
  overflow: auto;

  > .topView {
    padding: 17.5px 20px 0;

    > .tView {
      color: #333;
      text-align: left;
      font-size: 24px;
    }

    > .searchView {
      display: flex;
      height: 44px;
      padding: 15px 0 0 0;


      > .keywordView {
        margin-left: 20px;

        > button {
          background: #3370ff;
          font-size: 16px;
          font-family: PingFangSC-Medium;
          color: #fff;
        }
      }
    }

  }

  > .contentView {
    padding: 0 15px 15px 15px;

    > .pagination {
      margin-top: 20px;
      text-align: right;
    }
  }

}

button {
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}

//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in .4s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out .4s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in .4s ease
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out .4s ease forwards
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
</style>
